<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Category Type</label>
          <b-form-select
            :value="categoryTypeFilter"
            :options="categoryTypeOptions"
            class="w-100"
            @input="(val) => $emit('update:categoryTypeFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <b-form-select
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
  BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormSelect,
  },
  props: {
    categoryTypeFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
  },
  setup() {
    const categoryTypeOptions = [
      { value: '', text: 'Show all' },
      {
        label: 'Fabric',
        options: [
          'Main Fabric',
          'Second Fabric',
          'Trims Fabric',
          'Lining',
          'Rib',
        ],
      },
      {
        label: 'Trims',
        options: [
          'Label',
          'Button',
          'Drawcord',
          'Elastic',
          'Hangtag',
          'Metal Eyelet',
          'Metal Snap',
          'Tape',
          'Zipper',
        ],
      },
      {
        label: 'Embellishments',
        options: [
          'Embroidery',
          'Print',
          'Heat Transfer',
          'Sublimation',
          'All over Print',
          'Rhinestones',
          'Finishings',
        ],
      },
      {
        label: 'Packaging',
        options: [
          'Box',
          'Bag',
          'Tapes',
          'Stickers',
          'Postcards',
          'Paper',
        ],
      },
    ]

    const statusOptions = [
      { value: '', text: 'Show all' },
      'On Specification',
      'Needs Sourcing Sample',
      'Needs Sourcing Sample with Development',
      'Brand own Sourcing',
      'Sent',
      'Approved',
      'Rejected',
    ]

    return {
      categoryTypeOptions,
      statusOptions,
    }
  },
}
</script>
