<template>

  <div>

    <!-- Filters -->
    <material-list-filters
      :category-type-filter.sync="categoryTypeFilter"
      :status-filter.sync="statusFilter"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refMaterialListTable"
        class="position-relative"
        :items="fetchMaterials"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Image -->
        <template #cell(image)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.image"
                square
                rounded="sm"
                text="M"
              />
            </template>
          </b-media>
        </template>

        <!-- Column: Description -->
        <template #cell(description)="data">
          <div class="text-nowrap">
            <div class="align-text-top">
              {{ data.item.specifics_description }}
            </div>
            <div class="align-text-top">
              {{ data.item.dimensions_description }}
            </div>
          </div>
        </template>

        <!-- Column: Style -->
        <template #cell(style)="data">
          <b-link
            :to="{ name: 'apps-styles-edit',
                   params: { id: data.item.style_id },
                   hash: `#${editTabs[data.item.category_type]}`,
            }"
            class="font-weight-bold d-block text-nowrap"
          >
            <div class="text-nowrap">
              <h6 class="table-cell-title my-0">
                {{ data.item.style_name }}
              </h6>
              <h6 class="text-muted my-0">
                {{ data.item.style_ref }}
              </h6>
            </div>
          </b-link>
        </template>

        <!-- Column: Date -->
        <template #cell(date)="data">
          <div
            class="text-nowrap"
            style="min-width: 100px;"
          >
            {{ data.item.date }}
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalMaterials"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>

import {
  BAvatar, BCard, BCol, BLink, BMedia, BPagination, BRow, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
  onUnmounted,
} from '@vue/composition-api'
import useMaterialsList from './useMaterialsList'
import materialStoreModule from '../materialStoreModule'
import MaterialListFilters from './MaterialListFilters.vue'

export default {
  components: {
    MaterialListFilters,

    BAvatar,
    BCard,
    BCol,
    BLink,
    BMedia,
    BRow,
    BPagination,
    BTable,

    vSelect,
  },
  methods: {
  },
  setup() {
    const MATERIAL_APP_STORE_MODULE = 'app-material'

    // Register module
    if (!store.hasModule(MATERIAL_APP_STORE_MODULE)) store.registerModule(MATERIAL_APP_STORE_MODULE, materialStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIAL_APP_STORE_MODULE)) store.unregisterModule(MATERIAL_APP_STORE_MODULE)
    })

    const editTabs = {
      'Main Fabric': 1,
      'Second Fabric': 1,
      'Trims Fabric': 1,
      Lining: 1,
      Rib: 1,

      Label: 2,
      Button: 2,
      Drawcord: 2,
      Elastic: 2,
      Hangtag: 2,
      'Metal Eyelet': 2,
      'Metal Snap': 2,
      Tape: 2,
      Zipper: 2,

      Embroidery: 3,
      Print: 3,
      'Heat Transfer': 3,
      Sublimation: 3,
      'All over Print': 3,
      Rhinestones: 3,
      Finishings: 3,

      Box: 5,
      Bag: 5,
      Tapes: 5,
      Stickers: 5,
      Postcards: 5,
      Paper: 5,
    }

    const {
      fetchMaterials,
      tableColumns,
      perPage,
      currentPage,
      totalMaterials,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refMaterialListTable,
      refetchData,

      categoryTypeFilter,
      statusFilter,
    } = useMaterialsList()

    return {
      fetchMaterials,
      tableColumns,
      perPage,
      currentPage,
      totalMaterials,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refMaterialListTable,
      refetchData,

      // Filter
      categoryTypeFilter,
      statusFilter,
      editTabs,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
